import * as React from 'react';
import { Text } from '@mentimeter/ragnar-ui/text';
import type { TextT } from '@mentimeter/ragnar-ui/text';

export const Heading = (props: TextT) => (
  <Text
    mb={2}
    fontSize={4}
    lineHeight="heading"
    color="text"
    fontWeight="semiBold"
    {...props}
  />
);

export const SubHeading = (props: TextT) => (
  <Text
    mb={2}
    fontSize={2}
    lineHeight="heading"
    color="text"
    fontWeight="semiBold"
    {...props}
  />
);

export const P = (props: TextT) => (
  <Text as="p" mb={2} lineHeight="body" {...props} />
);

export const Strong = (props: TextT) => (
  <Text
    fontWeight="semiBold"
    fontSize="inherit"
    lineHeight="inherit"
    {...props}
  />
);

export const Meta = (props: TextT) => (
  <Text fontSize={1} lineHeight="body" color="textWeaker" {...props} />
);

import type { PlanCategory, CurrencyT } from '@mentimeter/http-clients';
import { core, isAxiosError } from '@mentimeter/http-clients';
import useSwr from 'swr';
import {
  calculateDaysUntilSubscriptionEnds,
  formatAmount,
  formatDate,
  formatDiscountPercentage,
  formatPercentage,
  formatPlanCategory,
  isConferencePlan,
  roundUpAmount,
} from './utils';
export interface InvoicePreviewResponseT {
  'invoice-preview': RawInvoicePreview;
}

// These are the intervals that are returned by Stripe, we only use month and year of these
type StripeIntervalT = 'day' | 'week' | 'month' | 'year';
// Conference plans are billed once, so we set that as the plan cycle for these (we don't get them like that from Stripe or BE)
export type PlanCycleT = StripeIntervalT | 'once';

export interface RequestInvoiceObjectT {
  planCategory: string;
  planCategoryRaw: PlanCategory;
  planUnitPrice: string | undefined;
  planTieredUnitPrice: string | undefined;
  planCycle: PlanCycleT | undefined;
  planBillingDate: string;
  planStartDate: string;
  licenses: number;
  subtotal: string | undefined;
  tax: string | undefined;
  taxPercentage: string | undefined;
  total: string | undefined;
}

export interface RawInvoicePreview {
  created: number;
  currency: CurrencyT;
  line_items: [LineItem] | [LineItem, LineItem];
  subtotal: number;
  tax: number;
  total: number;
  plan_pricing: PlanPricing;
  proration_date: number;
}

export interface MinimalInvoicePreviewT {
  created: number;
  currency: CurrencyT;
  line_items: [LineItem] | [LineItem, LineItem];
  subtotal: number;
  tax: number | undefined;
  total: number;
  plan_pricing: PlanPricing;
  proration_date: number | null;
}

interface PlanPricing {
  current_plan: {
    base_unit_amount: number;
  };
  preview_plan?: PreviewPlan;
}

interface PreviewPlan {
  base_unit_amount: number;
  tiered_unit_amount: number;
  total_discount: number;
  discount_percentage: number;
}

interface LineItem {
  quantity: number;
  subtotal_amount: number;
  plan_category: PlanCategory;
  interval: StripeIntervalT | undefined;
  interval_count: number;
  period_start: number;
  period_end: number;
  tax_amount: number;
}

export const formatMinimalResponse = (
  response: MinimalInvoicePreviewT,
): RequestInvoiceObjectT => {
  const planCategory = response.line_items[0]?.plan_category;
  const planCycle = isConferencePlan(planCategory)
    ? 'once'
    : response.line_items[0]?.interval;

  return {
    planCategory: formatPlanCategory(response.line_items[0]?.plan_category),
    planCategoryRaw: response.line_items[0]?.plan_category,
    planUnitPrice: formatAmount({
      amount: response.plan_pricing?.preview_plan?.base_unit_amount,
      currency: response.currency?.iso_code,
      subUnit: response.currency?.subunit_to_unit,
    }),
    planTieredUnitPrice: formatAmount({
      amount: response.plan_pricing?.preview_plan?.tiered_unit_amount,
      currency: response.currency?.iso_code,
      subUnit: response.currency?.subunit_to_unit,
    }),
    planCycle,
    planBillingDate: formatDate(response.line_items[0]?.period_end),
    planStartDate: formatDate(response.line_items[0]?.period_start),
    licenses: response.line_items[0]?.quantity,
    subtotal: formatAmount({
      amount: response.subtotal,
      currency: response.currency?.iso_code,
      subUnit: response.currency?.subunit_to_unit,
    }),
    tax: formatAmount({
      amount: response.tax,
      currency: response.currency?.iso_code,
      subUnit: response.currency?.subunit_to_unit,
    }),
    taxPercentage: formatPercentage(response.tax, response.subtotal),
    total: formatAmount({
      amount: response.total,
      currency: response.currency?.iso_code,
      subUnit: response.currency?.subunit_to_unit,
    }),
  };
};

const formatResponse = (
  response: InvoicePreviewResponseT['invoice-preview'],
) => {
  const newPlanTotalPriceCalc =
    response.plan_pricing?.preview_plan?.base_unit_amount &&
    response.line_items[1]?.quantity
      ? response.plan_pricing?.preview_plan?.base_unit_amount *
        response.line_items[1]?.quantity
      : undefined;
  return {
    currentPlanCategory: formatPlanCategory(
      response.line_items[0]?.plan_category,
    ),
    currentPlanCategoryRaw: response.line_items[0]?.plan_category,
    currentPlanUnitPrice: formatAmount({
      amount: response.plan_pricing?.current_plan?.base_unit_amount,
      currency: response.currency?.iso_code,
      subUnit: response.currency?.subunit_to_unit,
    }),
    currentPlanTotalPrice: formatAmount({
      amount:
        response.plan_pricing?.current_plan?.base_unit_amount *
        response.line_items[0]?.quantity,
      currency: response.currency?.iso_code,
      subUnit: response.currency?.subunit_to_unit,
    }),
    currentPlanCycle: response.line_items[0]?.interval,
    currentPlanBillingDate: formatDate(response.line_items[0]?.period_end),
    daysUntilSubscriptionEnds: calculateDaysUntilSubscriptionEnds(
      response.line_items[0]?.period_end,
    ),
    currentPlanStartDate: formatDate(response.line_items[0]?.period_start),
    currentPlanLicenses: response.line_items[0]?.quantity,
    newPlanCategory: formatPlanCategory(response.line_items[1]?.plan_category),
    newPlanCategoryRaw: response.line_items[1]?.plan_category,
    newPlanUnitPrice: formatAmount({
      amount: response.plan_pricing?.preview_plan?.base_unit_amount,
      currency: response.currency?.iso_code,
      subUnit: response.currency?.subunit_to_unit,
    }),
    newPlanTotalPrice: formatAmount({
      amount: newPlanTotalPriceCalc,
      currency: response.currency?.iso_code,
      subUnit: response.currency?.subunit_to_unit,
    }),
    newPlanCycle: response.line_items[1]?.interval,
    newPlanBillingDate: formatDate(response.line_items[1]?.period_end),
    newPlanLicenses: response.line_items[1]?.quantity,
    isSameBillingDate: false,
    progressiveDiscountRate:
      (response.plan_pricing?.preview_plan?.discount_percentage || 0) / 100,
    progressiveDiscountAmount: formatAmount({
      amount: response.plan_pricing?.preview_plan?.total_discount,
      currency: response.currency?.iso_code,
      subUnit: response.currency?.subunit_to_unit,
    }),
    proratedDiscount: formatAmount({
      amount: response.line_items[0]?.subtotal_amount,
      currency: response.currency?.iso_code,
      subUnit: response.currency?.subunit_to_unit,
    }),

    subtotal: formatAmount({
      amount:
        response.subtotal +
        Math.abs(response.line_items[0]?.subtotal_amount) +
        Math.abs(response.plan_pricing?.preview_plan?.total_discount || 0) +
        response.tax,
      currency: response.currency?.iso_code,
      subUnit: response.currency?.subunit_to_unit,
    }),
    subtotalAfterDiscounts: formatAmount({
      amount: response.subtotal,
      currency: response.currency?.iso_code,
      subUnit: response.currency?.subunit_to_unit,
    }),
    tax: formatAmount({
      amount: response.tax,
      currency: response.currency?.iso_code,
      subUnit: response.currency?.subunit_to_unit,
    }),
    taxPercentage: formatPercentage(response.tax, response.subtotal),
    total: formatAmount({
      amount: response.total,
      currency: response.currency?.iso_code,
      subUnit: response.currency?.subunit_to_unit,
    }),
    roundedTotal: roundUpAmount(response.total),
    discountPercentage: formatDiscountPercentage(
      response.plan_pricing?.current_plan?.base_unit_amount,
      response.plan_pricing?.preview_plan?.base_unit_amount,
      response.line_items[0]?.interval ?? '',
      response.line_items[1]?.interval ?? '',
    ),
    prorationDate: response.proration_date,
  };
};

export type InvoicePreviewT = ReturnType<typeof formatResponse>;

interface PreviewPropsT {
  plan?: PlanCategory;
  licenses?: number;
  options?: {
    skip?: boolean;
    revalidateOnFocus?: boolean;
  };
  overrideCancel?: boolean;
}

const defaultOptions = {
  skip: false,
  revalidateOnFocus: false,
};

export function useInvoicePreview({
  plan,
  licenses,
  overrideCancel,
  options = defaultOptions,
}: PreviewPropsT) {
  const { skip, revalidateOnFocus } = { ...defaultOptions, ...options };
  const shouldFetch = !skip;

  const {
    data: invoicePreview,
    error,
    isLoading,
  } = useSwr(
    shouldFetch ? ['invoice-preview', plan, licenses] : null,
    async () => {
      const { data } = await core().get<InvoicePreviewResponseT>(
        `/payment-services/invoices/subscription-update/preview`,
        {
          params: {
            licenses,
            plan_category: plan,
            ignore_canceled_renewal: overrideCancel,
          },
        },
      );

      return formatResponse(data['invoice-preview']);
    },
    {
      revalidateOnFocus,
      // Since we are dealing with proration, we need to make sure that the data is always up to date
      // This is why we set the refresh interval to 5 minutes
      refreshInterval: 300_000,
    },
  );

  if (isLoading) {
    return {
      isLoading: true,
      invoicePreview: undefined,
      error: undefined,
    };
  }

  if (error || !invoicePreview) {
    return {
      isLoading: false,
      invoicePreview: undefined,
      error,
    };
  }

  return {
    isLoading: false,
    invoicePreview,
    error: undefined,
  };
}

export enum NewInvoicePreviewTypesEnum {
  INVOICE_REQUEST = 'invoice_request',
  CHECKOUT = 'checkout',
}

interface NewInvoicePreviewPropsT {
  plan: string;
  licenses: number;
  country?: string;
  state?: string;
  postal_code?: string;
  tax_id_value?: string;
  tax_id_type?: string;
  preview_type: NewInvoicePreviewTypesEnum;
  options: {
    skip: boolean;
    revalidateOnFocus?: boolean;
    shouldRetryOnError?: boolean;
  };
  overrideCancel?: boolean;
}

interface RequestParamsT {
  licenses: number;
  plan_category: string;
  preview_type: NewInvoicePreviewTypesEnum;
  'address[country]'?: string;
  'address[state]'?: string;
  'address[postal_code]'?: string;
  'tax_id[type]'?: string;
  'tax_id[value]'?: string;
}

export const useMinimalInvoicePreview = ({
  licenses,
  plan,
  country,
  state,
  postal_code,
  tax_id_value,
  tax_id_type,
  preview_type,
  options: {
    skip = false,
    shouldRetryOnError = false,
    revalidateOnFocus = false,
  },
}: NewInvoicePreviewPropsT) => {
  const swrRoute = 'minimal-invoice-preview/ppp';
  const shouldFetch = !skip;

  const {
    data: minimalInvoicePreview,
    isLoading,
    error,
  } = useSwr(
    shouldFetch
      ? [
          swrRoute,
          licenses,
          plan,
          country,
          postal_code,
          tax_id_value,
          tax_id_type,
        ]
      : null,
    async () => {
      const requestParams: RequestParamsT = {
        licenses,
        plan_category: plan,
        preview_type,
      };

      if (country) {
        requestParams['address[country]'] = country;
      }
      if (state) {
        requestParams['address[state]'] = state;
      }
      if (postal_code) {
        requestParams['address[postal_code]'] = postal_code;
      }
      // if the customer hasn't filled in the whole tax object, we want to remove it
      // so they can progress the invoice flow without it
      if (tax_id_value && tax_id_type) {
        requestParams['tax_id[type]'] = tax_id_type;
        requestParams['tax_id[value]'] = tax_id_value;
      }

      try {
        const response = await core().get(
          `/payment-services/invoices/first-purchase/preview`,
          { params: requestParams },
        );

        return formatMinimalResponse(response.data);
      } catch (error) {
        if (isAxiosError(error)) {
          // @ts-expect-error-auto TS(2571) FIXME: Object is of type 'unknown'.
          throw error.response?.data?.data?.errors;
        }
        throw error;
      }
    },

    { shouldRetryOnError, revalidateOnFocus },
  );

  return {
    isLoading,
    minimalInvoicePreview,
    error,
  };
};

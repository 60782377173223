import useSWR from 'swr';
import type { AxiosErrorToJSON, PlanCategory } from '@mentimeter/http-clients';
import { core } from '@mentimeter/http-clients';

interface UpdateOptionsT {
  plan_category: PlanCategory;
  licenses: { from: number; up_to: number };
}

export interface UpdateOptionsResponseT {
  current_plan: {
    plan_category: PlanCategory;
    licenses: number;
  };
  update_options: UpdateOptionsT[];
}

export const useUpdateOptions = (skip = false) => {
  const { data: possibleUpdates, error } = useSWR(
    !skip ? 'payment-services/subscriptions/update-options' : null,
    async () => {
      const { data } = await core().get<UpdateOptionsResponseT>(
        '/payment-services/subscriptions/update-options',
      );

      return data;
    },
  );

  return {
    isLoading: !possibleUpdates && !error,
    possibleUpdates,
    error: error as AxiosErrorToJSON,
  };
};

export const getValidPlan = (
  toPlan: string,
  possibleUpdates: UpdateOptionsResponseT,
): UpdateOptionsT | undefined => {
  return possibleUpdates?.update_options.find(
    (plan) => plan.plan_category === toPlan,
  );
};

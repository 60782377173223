import { Svg } from '@mentimeter/ragnar-ui/svg';

export const PayPalIcon = ({ large = false }: { large?: boolean }) => (
  <Svg
    width={large ? '44' : '22'}
    height={large ? '44' : '22'}
    viewBox="0 0 22 22"
  >
    <path
      d="M5.03931 20.3195L5.40203 18.0042L4.59405 17.9853H0.73584L3.4171 0.900536C3.42508 0.848334 3.45146 0.800753 3.49144 0.766466C3.53141 0.732179 3.58232 0.71347 3.63487 0.713748H10.1404C12.3001 0.713748 13.7905 1.16538 14.5687 2.05681C14.9335 2.47499 15.1658 2.91199 15.2782 3.3929C15.3961 3.8975 15.3981 4.50038 15.283 5.23569L15.2747 5.28935V5.7605L15.6395 5.9682C15.918 6.10999 16.1683 6.3018 16.3781 6.53414C16.6902 6.89169 16.892 7.34611 16.9774 7.88487C17.0654 8.43896 17.0363 9.09829 16.8921 9.84474C16.7256 10.7034 16.4565 11.4513 16.0931 12.0632C15.7724 12.6114 15.3413 13.0862 14.8274 13.4571C14.3446 13.8014 13.7711 14.0628 13.1226 14.2301C12.4943 14.3946 11.7778 14.4775 10.992 14.4775H10.4857C10.1237 14.4775 9.77208 14.6085 9.49605 14.8434C9.22041 15.0808 9.03756 15.4088 8.98005 15.769L8.94191 15.9774L8.30107 20.0581L8.27194 20.208C8.26431 20.2554 8.25113 20.2791 8.23171 20.2951C8.2129 20.3106 8.18943 20.3192 8.16513 20.3195H5.03931Z"
      fill="#253B80"
    />
    <path
      d="M15.985 5.34363C15.9656 5.46839 15.9434 5.59593 15.9184 5.72696C15.0605 10.1534 12.1254 11.6826 8.37675 11.6826H6.4681C6.00967 11.6826 5.62336 12.0171 5.55192 12.4715L4.57472 18.6997L4.29799 20.4651C4.28697 20.5351 4.29117 20.6067 4.31033 20.6749C4.32948 20.7432 4.36312 20.8064 4.40893 20.8604C4.45474 20.9143 4.51163 20.9576 4.57569 20.9873C4.63975 21.017 4.70946 21.0324 4.78001 21.0324H8.16522C8.56609 21.0324 8.90662 20.7397 8.96973 20.3424L9.00302 20.1696L9.64039 16.1048L9.68131 15.8818C9.74373 15.4831 10.085 15.1904 10.4858 15.1904H10.9921C14.2719 15.1904 16.8394 13.8522 17.5898 9.97987C17.9033 8.3622 17.741 7.01148 16.9116 6.06151C16.6487 5.76764 16.3347 5.52437 15.985 5.34363Z"
      fill="#179BD7"
    />
    <path
      d="M15.0874 4.98402C14.8134 4.90438 14.5348 4.84223 14.253 4.79793C13.6963 4.71195 13.1337 4.6707 12.5705 4.67456H7.4715C7.27745 4.67441 7.08975 4.74403 6.94232 4.87084C6.7949 4.99764 6.69748 5.17326 6.66768 5.36596L5.58297 12.2701L5.55176 12.4716C5.58555 12.2518 5.6965 12.0514 5.86455 11.9067C6.03259 11.762 6.24663 11.6825 6.46794 11.6826H8.37658C12.1252 11.6826 15.0603 10.1527 15.9182 5.72699C15.9439 5.59596 15.9654 5.46841 15.9848 5.34366C15.7584 5.22435 15.5225 5.12434 15.2795 5.04466C15.2158 5.02341 15.1517 5.0032 15.0874 4.98402Z"
      fill="#222D65"
    />
    <path
      d="M6.66771 5.36597C6.69726 5.17322 6.79463 4.99752 6.94213 4.87078C7.08963 4.74404 7.27747 4.67467 7.47153 4.67527H12.5705C13.1746 4.67527 13.7384 4.715 14.2531 4.79864C14.6013 4.85364 14.9447 4.9359 15.2802 5.04467C15.5333 5.129 15.7685 5.22867 15.9855 5.34367C16.2408 3.70788 15.9835 2.59412 15.1033 1.58561C14.1331 0.475333 12.3819 0 10.141 0H3.63552C3.17778 0 2.78731 0.334545 2.71657 0.789667L0.00687286 18.0501C-0.00574844 18.1303 -0.000946461 18.2122 0.0209485 18.2903C0.0428434 18.3684 0.0813117 18.4408 0.133707 18.5025C0.186103 18.5642 0.251182 18.6138 0.324468 18.6479C0.397754 18.6819 0.477508 18.6996 0.558244 18.6997H4.57458L5.583 12.2702L6.66771 5.36597Z"
      fill="#253B80"
    />
  </Svg>
);

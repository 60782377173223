import type { ReactNode } from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';

export const ErrorMessageWrapper = ({ children }: { children: ReactNode }) => (
  <Box p="space8" width="100%">
    <Box
      borderColor="borderWeak"
      borderStyle="solid"
      borderWidth={1}
      borderRadius={1}
      width="100%"
      alignItems="center"
      p="space8"
    >
      {children}
    </Box>
  </Box>
);

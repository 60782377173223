import React from 'react';
import { useTranslations } from '@mentimeter/i18n';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';

interface PlanBlockProps {
  type: 'current' | 'new';
  planName: string | undefined;
  licenses: number | undefined;
  cycle: string | undefined;
  hasTax: boolean | undefined;
  taxPercentage: string | undefined;
  price: string | undefined;
}

export const PlanBlock = ({
  type,
  planName,
  licenses,
  cycle,
  hasTax,
  taxPercentage,
  price,
}: PlanBlockProps) => {
  const t = useTranslations('checkoutUiPackage');
  const textColor = type === 'current' ? 'textWeaker' : 'text';
  const typeStyle = type === 'current' ? 'surfaceSunken' : 'surface';
  const borderColor = type === 'current' ? 'surfaceSunken' : 'secondary';

  return (
    <Box
      borderWidth={1}
      borderStyle="solid"
      bg={typeStyle}
      borderRadius="lg"
      py={['space3', 'space4', 'space4', 'space8']}
      px={['space3', 'space5', 'space5', 'space10']}
      borderColor={borderColor}
      flex="1 1 205px"
    >
      {licenses && cycle && planName && (
        <>
          <Text fontSize="112.5" color={textColor}>
            {planName} {type === 'current' && t('checkout_modal.current')}
          </Text>
          <Box mt="space2">
            <Box>
              <Text color="textWeaker" fontSize="87.5">
                {t('licenses', { count: licenses })}{' '}
                {t('checkout_modal.billed', { billingCycle: t(cycle) })}
                {hasTax ? ` +${taxPercentage} ${t('tax').toLowerCase()}` : ''}
              </Text>
            </Box>
            <Text mt="space4" color={textColor}>
              {/* eslint-disable-next-line menti-react-jsx/no-possibly-undefined-in-jsx */}
              {price} {t(cycle)}
            </Text>
          </Box>
        </>
      )}
    </Box>
  );
};

import React from 'react';
import { CrossIcon } from '@mentimeter/ragnar-visuals';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import { ModalDismiss } from '@mentimeter/ragnar-ui/modal';

interface CloseModalProps {
  onCloseModal: () => void;
  iconSize?: number;
}

export function CloseModal({ onCloseModal, iconSize }: CloseModalProps) {
  return (
    <ModalDismiss>
      <Clickable
        aria-label="Close modal"
        position="absolute"
        top={0}
        right={0}
        zIndex={1}
        onClick={onCloseModal}
        extend={({ theme }) => ({
          padding: `${theme.space[2] + theme.space[1]}px`,
        })}
      >
        <CrossIcon size={iconSize || 4} />
      </Clickable>
    </ModalDismiss>
  );
}

import { Trans, useTranslations } from '@mentimeter/i18n';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';

interface BankTransferProps {
  email: string;
}

export function BankTransferPaymentMethod({ email }: BankTransferProps) {
  const t = useTranslations('checkoutUiPackage');

  return (
    <Box width="100%">
      <Text variant="dashboardBodySm">
        <Trans
          t={t}
          i18nKey="checkout_modal.payment_method.bank_transfer"
          values={{ email }}
          components={{
            bold: <Text variant="dashboardBodySm" fontWeight="semiBold" />,
          }}
        />
      </Text>
    </Box>
  );
}
